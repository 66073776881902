import PropTypes from "prop-types";
import { useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase, CardContent, ClickAwayListener, Grid, IconButton, Paper, Popper, Stack, Typography } from "@mui/material";

// project import
import MainCard from "../../../../components/MainCard";
import Transitions from "../../../../components/@extended/Transitions";
import Authorization from "../../../../utils/authorization";
// import ProfileTab from './ProfileTab';
// import SettingTab from './SettingTab';

// assets
import avatar1 from "../../../../assets/images/unknownprofile.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MiModal from "../../../../components/ui/MiModal";
import { LOGOUT_NAVI_MESSAGE } from "../../../../constants";
import { logout } from "../../../../utils/session";

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `profile-tab-${index}`,
//     "aria-controls": `profile-tabpanel-${index}`,
//   };
// }

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const { naviBlocker } = useSelector((state) => state.common);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    setMiModalShow(true);
    logout();
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [miModalShow, setMiModalShow] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // const [value, setValue] = useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const iconBackColorOpen = "grey.300";
  const authUser = Authorization.getAuthUser();
  const ok = () => {
    setMiModalShow(false);
    navigate("/login");
    !naviBlocker.naviBlock && Authorization.logout();
    !naviBlocker.naviBlock && dispatch({ type: "RESET_STORE" });
  };
  const cancel = () => {
    setMiModalShow(false);
  };

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <ButtonBase
          sx={{
            p: 0.25,
            bgcolor: open ? iconBackColorOpen : "transparent",
            borderRadius: 1,
            "&:hover": { bgcolor: "secondary.lighter" },
          }}
          aria-label="open profile"
          ref={anchorRef}
          aria-controls={open ? "profile-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
            <Avatar alt="profile user" src={avatar1} sx={{ width: 28, height: 28 }} style={{ backgroundColor: "#00617F", padding: 6 }} />
            <Typography variant="subtitle1" letterSpacing={"0.05em"}>{`${authUser?.firstname} ${authUser?.lastname}`}</Typography>
          </Stack>
        </ButtonBase>
        <Popper
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 9],
                },
              },
            ],
          }}
        >
          {({ TransitionProps }) => (
            <Transitions type="fade" in={open} {...TransitionProps}>
              {open && (
                <Paper
                  sx={{
                    boxShadow: theme.customShadows.z1,
                    width: 290,
                    minWidth: 240,
                    maxWidth: 290,
                    [theme.breakpoints.down("md")]: {
                      maxWidth: 250,
                    },
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard elevation={0} border={false} content={false}>
                      <CardContent sx={{ px: 2.5, pt: 3 }}>
                        <Grid container justifyContent="space-between" alignItems="center">
                          <Grid item>
                            <Stack direction="row" spacing={1.25} alignItems="center">
                              <Avatar
                                alt="profile user"
                                src={avatar1}
                                sx={{ width: 28, height: 28 }}
                                style={{ backgroundColor: "#00617F", padding: 6 }}
                              />
                              <Stack>
                                <Typography variant="h6">{`${authUser?.firstname} ${authUser?.lastname}`}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                  {authUser?.userrole}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid item>
                            <IconButton size="large" color="secondary" onClick={handleLogout}>
                              <LogoutIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                      {open && (
                        <>
                          {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs">
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize'
                              }}
                              icon={<PersonIcon style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Profile"
                              {...a11yProps(0)}
                            />
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize'
                              }}
                              icon={<SettingsIcon style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Setting"
                              {...a11yProps(1)}
                            />
                          </Tabs>
                        </Box> */}
                          {/* <TabPanel value={value} index={0} dir={theme.direction}>
                          <ProfileTab handleLogout={handleLogout} />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                          <SettingTab />
                        </TabPanel> */}
                        </>
                      )}
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              )}
            </Transitions>
          )}
        </Popper>
      </Box>
      <MiModal
        open={miModalShow}
        title="Confirmation"
        description={LOGOUT_NAVI_MESSAGE}
        ok={{ buttonName: "Proceed", ok: ok }}
        cancel={{ buttonName: "Cancel", cancel: cancel }}
      />
    </>
  );
};

export default Profile;
