import React, { useState, useEffect } from "react";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { apiURL } from "../../../services/apiUrlConfig";
import { getRequest } from "../../../services/apiUtils";
import { openSnackbar } from "../../../components/ui/Toastr";
import { ErrorMsg } from "../../../utils/helper";
import { Grid, Box, Typography } from "@mui/material";

const accountsData = {
  labels: [],
  datasets: [
    {
      label: "",
      data: [],
      backgroundColor: ["#B4D88D", "#6093A3", "#DD6489"],
      cutout: "70%",
    },
  ],
};

const accountsOptions = {
  type: "doughnut",
  data: accountsData,
  options: {
    aspectRatio: 1,
    maintainAspectRatio: false,
    responsive: false,
    plugins: {
      title: {
        display: false,
      },
    },
  },
};

const Accounts = ({ callbackLoader, selectedCountryCode, loggedUser, selectedYear }) => {
  const [accountsChartData, setAccountsChartData] = useState(accountsData);

  const [accountsStatus, setAccountStatus] = useState({});
  const [discountStatus, setdiscountStatus] = useState({});

  useEffect(() => {
    getLoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountryCode, selectedYear]);

  const getLoadData = () => {
    const { ppp } = apiURL;
    callbackLoader(true);
    getRequest({
      url: `${ppp.getDashboardStatus}/${loggedUser?.id}/${selectedYear}`,
      queryParams: {
        countryCode: selectedCountryCode === "ALL" ? null : selectedCountryCode,
      },
    })
      .then((response) => {
        callbackLoader(false);
        const result = response?.body;
        if (Object.keys(result)?.length > 0) {
          setAccountStatus(result);
          let objectValue = result;
          let keyAndValueArray = [];
          let totalValue = objectValue?.Total;
          let discountObj = {};
          for (let property in objectValue) {
            if (property !== "Total") {
              let obj = {
                key: property,
                value: ((objectValue[property] / totalValue) * 100).toFixed(0),
              };
              discountObj = { ...discountObj, [property]: obj.value };
              keyAndValueArray.push(obj.value);
            }
          }
          setdiscountStatus({ ...discountObj });
          const dataSetData = accountsChartData?.datasets?.map((el) => {
            return (el = { ...el, data: [...keyAndValueArray] });
          });
          setAccountsChartData({
            ...accountsChartData,
            datasets: dataSetData,
          });
        } else {
          setAccountsChartData(accountsData);
        }
      })
      .catch((error) => {
        callbackLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ position: "relative" }}>
        <Doughnut options={accountsOptions} data={accountsChartData} width={180} height={180} />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "black",
          }}
        >
          <div style={{ fontSize: "1.25rem", fontWeight: "bold" }}>{accountsStatus?.Total}</div>
          <div style={{ fontSize: "0.9rem", fontWeight: "bold" }}>accounts</div>
        </div>
      </div>
      <div style={{ marginLeft: "1rem" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} columnSpacing={3}>
            <Grid item xs={7}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                <div
                  style={{
                    borderRadius: "50%",
                    height: "15px",
                    width: "15px",
                    backgroundColor: "#A9D677",
                  }}
                ></div>
                <div>
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    Completed ({discountStatus?.Completed}%)
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                {accountsStatus?.Completed} Accounts
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                <div
                  style={{
                    borderRadius: "50%",
                    height: "15px",
                    width: "15px",
                    backgroundColor: "#5595A8",
                  }}
                ></div>
                <div>
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    In progress ({discountStatus?.InProgress}%)
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                {accountsStatus?.InProgress} Accounts
              </Typography>
            </Grid>

            <Grid item xs={7}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
                <div
                  style={{
                    borderRadius: "50%",
                    height: "15px",
                    width: "15px",
                    backgroundColor: "#DE5A82",
                  }}
                ></div>
                <div>
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    Not started ({discountStatus?.Pending}%)
                  </Typography>
                </div>
              </Box>
            </Grid>

            <Grid item xs={5}>
              <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                {accountsStatus?.Pending} Accounts
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default Accounts;
