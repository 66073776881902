import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createAssessmentloading: false,
  createAssessmentResponse: {},
  createAssessmentError: false,
};

const createAssessmentRedux = createSlice({
  name: "createAssessment",
  initialState,
  reducers: {
    createAssessment(state) {
      return { ...state, createAssessmentloading: true };
    },
    createAssessment_successful(state, action) {
      return {
        ...state,
        createAssessmentResponse: action.payload,
        createAssessmentloading: false,
      };
    },
    createAssessment_error(state, action) {
      return {
        ...state,
        createAssessmentResponse: action.payload?.response,
        createAssessmentloading: false,
        createAssessmentError: true,
      };
    },
    resetStatus(state) {
      return {
        ...state,
        createAssessmentloading: false,
        createAssessmentError: false,
        createAssessmentResponse: {},
      };
    },
  },
});

export const { createAssessment, createAssessment_successful, createAssessment_error, resetStatus } = createAssessmentRedux.actions;

export default createAssessmentRedux.reducer;
