import ReactCountryFlag from "react-country-flag";

const CountryFlag = ({ countryCode, styles }) => {
  const condStyles = styles || {
    width: "2em",
    height: "2em",
    marginRight: "8px",
  };

  return <ReactCountryFlag countryCode={countryCode} svg style={condStyles} />;
};

export default CountryFlag;
