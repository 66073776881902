import { React, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Childs } from "../constants";
import { BrView } from "../pages/PPP/Activities/ActivityTypes/ActivityTypeView";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { CustomButton } from "../components/ui/Button";
import { useSelector } from "react-redux";
import CountryFlag from "../components/ui/CountryFlag";
import Button from "@mui/material/Button";
import { Avatar } from "@mui/material";

const common_tabs = ["Home", "Activities"];

export function dynoNavigate(Component) {
  const Activityalloc = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const activityClick = (selectedActDetails) => {
      if (props.enable) {
        const dynoRoute = props?.activitydetails;
        const crdData = location.state?.data;
        const rtpath = Childs[(selectedActDetails?.ActivityId).toUpperCase()];
        const titlepath = `${location?.pathname}/${rtpath}`;
        navigate(Childs[(selectedActDetails?.ActivityId).toUpperCase()], {
          state: {
            data: { ...crdData, ...dynoRoute },
            obj: [dynoRoute?.activityname],
            title: { ...location?.state?.title, [titlepath]: dynoRoute?.activitydisplayname },
          },
        });
      }
    };
    return <Component {...props} onClick={() => activityClick(props)} />;
  };
  return Activityalloc;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: "4px 8px",
  textAlign: "center",
  color: theme.palette.text.secondary,
  flexGrow: 1,
  border: "1px solid #ADD8E6",
  boxShadow: " 0px 2px 1px -1px #ADD8E6, 0px 1px 1px 0px #ADD8E6, 0px 1px 3px 0px #ADD8E6",
}));

const DisplayButtonText = ({ text, width }) => {
  const getConditionalValues = useSelector((state) => state?.contracts?.condsAndUncondsObj);
  return (
    <Box sx={{ minWidth: width || 225 }}>
      <Stack direction="row" flexWrap={"nowrap"}>
        <Item>
          <CountryFlag countryCode={getConditionalValues?.countryInfo?.code} />
          {text}
        </Item>
      </Stack>
    </Box>
  );
};
export function enRouteActivity(Component) {
  const RouteDir = (props) => {
    const getConditionalValues = useSelector((state) => state?.contracts?.condsAndUncondsObj);
    const navigate = useNavigate();
    const redirectAssessmentPage = () => {
      const rtpath = Childs[(Childs?.ASSESSMENT).toUpperCase()];
      const titlepath = `${location?.pathname}/${rtpath}`;
      navigate(`${location.pathname}/${Childs?.ASSESSMENT}`, {
        state: {
          obj: ["Assessment"],
          title: { ...location?.state?.title, [titlepath]: "ASSESSMENT" },
        },
      });
    };
    const location = useLocation();
    let dpName = common_tabs.concat(location.state?.obj);
    const pathnames = location.pathname.split("/").filter((x) => x);
    return (
      <Fragment>
        <div>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <BrView crdData={location.state?.data} crdNames={location.state?.obj} dpName={dpName} paths={pathnames} />
            {pathnames?.length > 0 && pathnames.length === 2 && (
              <Box>
                <Button
                  variant="text"
                  sx={{
                    color: "#6bbd49",
                    borderRadius: "28px",
                    height: "35px",
                    cursor: "default",
                    backgroundColor: "#e6e6fa",
                    fontSize:"12px",
                    fontWeight: "bold",
                    letterSpacing: "0.07em",
                    "& .MuiButton-endIcon ": { marginRight: "-8px !important" },
                  }}
                  endIcon={
                    <Avatar sx={{ width: 40, height: 40,fontSize:"inherit !important", backgroundColor: "#389e0d" }}>
                      {getConditionalValues?.capabilityscore?.toFixed(2) || "--"}
                    </Avatar>
                  }
                >
                  Capability Score
                </Button>
                <CustomButton
                  label="View Assessment"
                  sx={{
                    color: "#fff",
                    borderRadius: "28px",
                    backgroundColor: "rgb(0, 145, 223)",
                    opacity: "1",
                    "&:hover": {
                      backgroundColor: "#0D47A1",
                    },
                    width: "175px",
                    marginLeft: "10px",
                    height: "35px",
                  }}
                  handleClick={redirectAssessmentPage}
                  className="cus-btn-arrow"
                  labelClassName={"label"}
                  labelSX={{
                    fontSize: 12,
                    fontWeight: "bold",
                    letterSpacing: "0.07em",
                  }}
                ></CustomButton>
              </Box>
            )}
            {pathnames?.length > 0 && pathnames.length > 2 && (
              <Box display={"flex"}>
                <DisplayButtonText text={location?.state?.title?.["/tracking/activities"] ?? ""} />
              </Box>
            )}
          </Box>
          <Box style={{ fontSize: 13, letterSpacing: 0.3, paddingTop: 10, height: 40, width: "100%", color: "#323232", fontWeight: 600 }}>
            {location?.state?.title?.[location.pathname] ?? ""}
          </Box>
        </div>
        <Component  {...props} />
      </Fragment>
    );
  };
  return RouteDir;
}
