import { useEffect, useState } from "react";
import CommonTable from "../../../../components/ui/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import { getAssessmentData, updateAssessmentData, previousAssessmentData } from "../../../../saga/saga/assessmentTableSaga";
import { Box } from "@mui/material";
import { CustomButton } from "../../../../components/ui/Button";
import MainCard from "../../../../components/MainCard";
import { useNavigate, useLocation } from "react-router-dom";
import { Childs, DEFAULT_NAVI_MESSAGE } from "../../../../constants";
import { apiURL } from "../../../../services/apiUrlConfig";
import util from "util";
import Authorization from "../../../../utils/authorization";
import { createAssessment, resetStatus } from "../../../../saga/saga/createAssessmentSaga";
import { openSnackbar } from "../../../../components/ui/Toastr";
import Loader from "../../../../components/ui/Loader";
import MiModal from "../../../../components/ui/MiModal";
import _ from "lodash";

let headerCells = [
  {
    id: "activityname",
    label: "Activity",
    numeric: false,
    disablePadding: true,
    minWidth: "auto",
    isInputType: "",
    isLast: false,
    userInteration: false,
    isMaxWidthApply: false,
    isReadOnly: false,
  },
  {
    id: "currentlevel",
    label: "Current Level",
    numeric: false,
    disablePadding: true,
    minWidth: "auto",
    isInputType: "",
    isLast: false,
    userInteration: false,
    isMaxWidthApply: false,
    isReadOnly: false,
  },
  {
    id: "assignedlevel",
    label: "Assigned Level",
    numeric: false,
    disablePadding: true,
    minWidth: "auto",
    isInputType: "dropdown",
    isLast: false,
    userInteration: true,
    isMaxWidthApply: false,
    options: [
      { label: "Level 1", value: "Level 1" },
      { label: "Level 2", value: "Level 2" },
      { label: "Level 3", value: "Level 3" },
      { label: "Level 4", value: "Level 4" },
    ],
    isReadOnly: false,
  },
  {
    id: "levelchangereason",
    label: "Reason For Change",
    numeric: false,
    disablePadding: true,
    minWidth: "600px",
    isInputType: "input",
    isLast: false,
    userInteration: true,
    isMaxWidthApply: true,
    inputProps: { maxLength: 1000 },
    isReadOnly: false,
  },
];

const Assessment = () => {
  //useState
  const [prevRowsState, setPrevRowsState] = useState([]);
  const [open, setOpen] = useState(false);
  const [headerArray, setHeaderArray] = useState(headerCells);
  //useSelector
  const tableResLoading = useSelector((state) => state.assessmentRedux?.tableDataLoading);
  const updateTableRows = useSelector((state) => state.assessmentRedux?.updatedRows);
  const previousTableRows = useSelector((state) => state.assessmentRedux?.previousRows);
  const createdAssessmentResp = useSelector((state) => state.createAssessmentRedux);
  const tableResponse = useSelector((state) => state.assessmentRedux?.tableDataUpdated);
  const initialTableResponse = useSelector((state) => state.assessmentRedux?.tableData);

  const { viewContractID } = useSelector((state) => state.common);

  //redux
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  //const
  const authUser = Authorization.getAuthUser();
  const isPayoutDone = initialTableResponse?.contract?.ispayoutdone;
  

  useEffect(() => {
    //maintain previous updated rows
    setPrevRowsState(previousTableRows);
  }, [previousTableRows]);

  useEffect(() => {
    let queryParams = `?type=LEVEL`;
    if(location?.state.prevCall){
      queryParams = `?type=PERCENT`; 
    }
    getLoadData(queryParams);
   
  }, []);

  useEffect(() => {
    if (createdAssessmentResp?.createAssessment_error) {
      const errormsg = createdAssessmentResp?.createAssessmentResponse?.data?.message;
      openSnackbar({
        message: errormsg || "Something Went Wrong",
        variant: "error",
      });
      dispatch(resetStatus());
    } else if (createdAssessmentResp?.createAssessmentResponse?.status === 200) {
      openSnackbar({
        message: "Assessment updated Successfully",
        variant: "success",
      });
      redirectPercentagePage(true);
      dispatch(previousAssessmentData(updateTableRows));
      dispatch(resetStatus());
    }
  }, [createdAssessmentResp]);

  useEffect(() => {
    const updatedHC = headerCells.map((hc) => (hc.isInputType !== "" ? { ...hc, isReadOnly: isPayoutDone ? true : false } : hc));
    setHeaderArray(updatedHC)
  }, [initialTableResponse]);

  const getLoadData = (queryParams) => {
    const { ppp } = apiURL;
    const condUrl = util.format(ppp.getAssessment, authUser?.id, viewContractID);
    dispatch(getAssessmentData(condUrl + queryParams));
  };

  const redirectAssessmentPage = () => {
    const rtpath = Childs[(Childs?.ACTIVITIES).toUpperCase()];
    const titlepath = `${location?.pathname}/${rtpath}`;
    navigate(`/tracking/activities`, {
      state: {
        obj: [""],
        title: { ...location?.state?.title, [titlepath]: "ASSESSMENT" },
      },
    });
  };

  const previousPage = () => {
    const validateResult = handleValidate();
    setOpen(validateResult);
    if (!validateResult) {
      redirectAssessmentPage();
    }
  };
  const redirectPercentagePage = (isUpdate) => {
    const rtpath = Childs[Childs?.PERCENTAGE.toUpperCase()];
    const titlepath = `${location?.pathname}/${rtpath}`;
    navigate(rtpath, {
      state: {
        data: { ...location.state.data },
        obj: ["Assessment", "Percentage"],
        title: { ...location?.state?.title, [titlepath]: "Percentage" },
      },
    });
  };

  const handleValidate = () => {
    const differenceArray = _.differenceWith(
      updateTableRows,
      prevRowsState,
      (a, b) => a?.assignedlevel === b?.assignedlevel && a?.levelchangereason === b?.levelchangereason
    );
    return differenceArray?.length ? true : false;
  };
  const redirectPage = (e) => {
    if(isPayoutDone){
      redirectPercentagePage()
    }else{
      e.preventDefault();
      let isValid = false;
      const errorArray =
        updateTableRows?.length > 0 &&
        updateTableRows?.map((tr) => {
          if (tr?.assignedlevel !== tr?.currentlevel && !tr?.levelchangereason) {
            tr = { ...tr, levelchangereason_Error: true };
          } else {
            tr = { ...tr, levelchangereason_Error: false };
          }
          if (tr?.levelchangereason_Error) {
            isValid = true;
          }
          return tr;
        });
      dispatch(updateAssessmentData(errorArray));
  
      if (!isValid && updateTableRows?.length) {
        let selectedArray = [];
        for (let i = 0; i < updateTableRows?.length; i++) {
          let obj = {
            activitytypeid: updateTableRows[i]?.activitytypeid,
            currentlevel: updateTableRows[i]?.currentlevel,
            assignedlevel: updateTableRows[i]?.assignedlevel,
            levelchangereason: updateTableRows[i]?.levelchangereason,
            conditionaldiscountpercent: updateTableRows[i]?.conditionaldiscountpercent,
          };
          selectedArray.push(obj);
        }
        const payloadData = { contract: { id: viewContractID, type: "LEVEL", activitytargets: selectedArray } };
        const { ppp } = apiURL;
        const condUrl = util.format(ppp.updateAssessmentContract, authUser?.id, viewContractID);
        const data = { url: condUrl, body: payloadData };
        dispatch(createAssessment(data));
      } else {
        openSnackbar({
          message: "Please enter the reason for change value",
          variant: "error",
        });
      }
    }
   
  };
  const modalProceed = () => {
    setOpen(false);
    redirectAssessmentPage();
    dispatch(updateAssessmentData(tableResponse));
  };
  const modalCancel = () => {
    setOpen(false);
  };
  return (
    <Box>
      {createdAssessmentResp?.createAssessmentloading && <Loader />}
      <MainCard elevation={0} border={false} content={true}>
        <CommonTable
          statusLoad={tableResLoading}
          rows={updateTableRows}
          headCells={headerArray}
          getFilterData={() => {}}
          getAPICall={() => {}}
          updateTableName="assessment"
        ></CommonTable>
        <Box display={"flex"} justifyContent={"flex-end"} m={1}>
          <CustomButton
            label="Previous"
            sx={{
              color: "rgb(0, 145, 223)",
              borderRadius: "28px",
              backgroundColor: "#fff",
              opacity: "1",
              width: "100px",
              marginLeft: "10px",
              height: "32px",
              border: "1px solid rgb(0, 145, 223)",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
            handleClick={previousPage}
            // className="cus-btn-arrow"
            labelClassName={"label"}
            labelSX={{
              fontSize: 12,
              fontWeight: "bold",
              letterSpacing: 0.3,
            }}
          ></CustomButton>
          <CustomButton
            label={!isPayoutDone ? "Finalise" : "Next"}
            sx={{
              color: "#fff",
              borderRadius: "28px",
              backgroundColor: "rgb(0, 145, 223)",
              opacity: "1",
              "&:hover": {
                backgroundColor: "#0D47A1",
              },
              width: "110px",
              marginLeft: "10px",
              height: "32px",
            }}
            handleClick={redirectPage}
            className="cus-btn-arrow"
            labelClassName={"label"}
            labelSX={{
              fontSize: 12,
              fontWeight: "bold",
              letterSpacing: 0.3,
            }}
          ></CustomButton>
        </Box>
      </MainCard>
      <MiModal
        open={open}
        title="Confirmation"
        description={DEFAULT_NAVI_MESSAGE}
        ok={{ buttonName: "Proceed", ok: modalProceed }}
        cancel={{ buttonName: "Cancel", cancel: modalCancel }}
      />
    </Box>
  );
};

export default Assessment;
