import {  createSlice } from '@reduxjs/toolkit'
const initialState = {
  contractsList: [],
  contractsloading: false,
  contractError: false
}

const userReducer = createSlice({

  name: 'createcontractReducer',
  initialState,
  reducers: {
    getContracts(state) {
      state.contractsloading = true;
      state.contractsList = [];

    },
    getContracts_successful(state, action) {
      state.contractsList = action.payload.body;
      state.contractsloading = false;
    },
    getContracts_error(state) {
      state.contractError = true;
    },
    resetContractCreated(state){
      state.contractError = false;
      state.contractsList = []
      state.contractsloading = false;
    }
  },
})

export const {
  getContracts,
  getContracts_successful,
  getContracts_error,
  resetContractCreated
} = userReducer.actions;

export default userReducer.reducer
