import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getAzureToken, saveOrUpdateRequest } from "../../services/apiUtils";
import { Fragment, useState, forwardRef } from "react";
import Authorization from "../../utils/authorization";
import { useNavigate } from "react-router-dom";
import { openSnackbar } from "../../components/ui/Toastr";
import { CustomButton } from "../../components/ui/Button";
import { useDispatch } from "react-redux";
import { loginDetails } from "../../features/login/loginSlice";
import LoginImg from "../../assets/images/login.svg";
import Loader from "../../components/ui/Loader";
import { apiURL } from "../../services/apiUrlConfig";
import Logo from "../../components/Logo";
import { MuiTelInput } from "mui-tel-input";
import "../../assets/scss/login.scss";
import Validator from "../../utils/validator";
import { MuiOtpInput } from "mui-one-time-password-input";
import FormHelperText from "@mui/material/FormHelperText";
import parsePhoneNumber from "libphonenumber-js";
import Counter from "./Counter";
import cookie from 'js-cookie';
import { ErrorMsg,ObjectIsEmpty } from "../../utils/helper";

const errEmailMsg = "Email Address or Mobile Number is required.";

const textFieldSX = {
  input: { height: "1.3em", padding: "13.5px 11px" },
  label: { lineHeight: "1em" },
};
function matchIsNumeric(text) {
  const isNumber = typeof text === "number";
  const isString = typeof text === "string";
  return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
}

const validateChar = (value, index) => {
  return matchIsNumeric(value);
};
const OtpInput = ({ getOtpCode, error }) => {
  const [otp, setOtp] = useState("");
  const handleChange = (newValue) => {
    setOtp(newValue);
    getOtpCode(newValue);
  };
  return (
    <Fragment>
      <MuiOtpInput
        value={otp}
        onChange={handleChange}
        length={6}
        validateChar={validateChar}
        required
        error={error}
      />
      {error && (
        <FormHelperText id="component-helper-text" error>
          {error}
        </FormHelperText>
      )}
    </Fragment>
  );
};

const RenderPhone = forwardRef((props, ref) => {
  const handleChange = (value, info) => {
    props.onChange(value, info);
  };
  return (
    <MuiTelInput
      value={props.value}
      name={props.name}
      autoFocus
      onChange={handleChange}
      fullWidth
      inputRef={ref}
    />
  );
});

function Login() {
  const dispatch = useDispatch();
  const [fields, setFields] = useState({
    username: "",
    password: "",
    showPassword: false,
    usernameError: "",
    passwordError: "",
    isAlpha: true,
    phoneInfo: {},
    otpError: "",
  });
  const [isLoader, SetLoader] = useState(false);
  const [sendOtpRes, setSendOtpRes] = useState({});
  const [otpCode, setOtpCode] = useState("");
  const [loginType, setLoginType] = useState("email");
  const [isCounterPage, SetCounterPage] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e, info) => {
    let obj = { ...fields };
    let condValue = info?.reason ? e : e.target.value;
    if (condValue === "" || condValue === null) {
      obj.username = condValue;
      obj.usernameError = errEmailMsg;
      obj.isAlpha = true;
      setLoginType("email");
    } else {
      obj.username = condValue;
      obj.usernameError = "";
      const firstLetter = condValue?.charAt(0);
      let alpha = Validator.validateAlpha(firstLetter);
      obj.isAlpha = alpha;
      obj.phoneInfo = { ...info };
      setLoginType(alpha ? "email" : "mobile");
    }
    if (e?.target?.value && !obj.isAlpha) {
      const phoneNumberInfo = parsePhoneNumber(e.target.value);
      let objPhone = {
        countryCode: phoneNumberInfo?.country,
        countryCallingCode: phoneNumberInfo?.countryCallingCode,
        nationalNumber: phoneNumberInfo?.nationalNumber,
        number: phoneNumberInfo?.number,
      };
      obj.phoneInfo = { ...objPhone };
    }
    //If country code is not found after set US countrycode
    if (!obj?.phoneInfo?.countryCode) {
      const value = obj?.phoneInfo?.numberValue || obj?.phoneInfo?.number;
      if (value) {
        const firstTwoChars = value?.substring(0, 2);
        if (firstTwoChars === "+1") {
          let phoneNumberDetails = parsePhoneNumber(value);
          let objPhone = {
            countryCode: phoneNumberDetails?.country || "US",
            countryCallingCode: phoneNumberDetails?.countryCallingCode,
            nationalNumber: phoneNumberDetails?.nationalNumber,
            number: phoneNumberDetails?.number,
          };
          obj.phoneInfo = { ...objPhone };
        }
      }
    }

    setFields({ ...obj });
  };
  const isEnvProd = () => {
    const isTrue = process.env.REACT_APP_ENVIRONMENT === "PROD" ? true : false;
    return isTrue;
  };
  const sendOTPToastrMsg = (codeValue) => {
    let condMsg = !isEnvProd() ? `OTP code is ${codeValue}` : "";
    const msg = `The OTP code has been successfully sent. ${condMsg} `;
    return msg;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { username, isAlpha, phoneInfo, otpError } = { ...fields };
    const isSendOtpConds =isSendOtp();
    let formValid = checkValidation();
    let payload = {};
    const { otp } = apiURL;
    let condPostURL = "";
    if (isSendOtpConds) {
      // Login Email Address or Mobile Number
      condPostURL = otp.sendOtp;
      if (!isAlpha) {
        payload = {
          phone: phoneInfo?.nationalNumber,
          dialer: `+${phoneInfo?.countryCallingCode}`,
          code: phoneInfo?.countryCode,
        };
      } else {
        payload = {
          email: username,
        };
      }
      payload = {
        ...payload,
        sendCode:true,
      };
    } else {
      // Verification the Code
      condPostURL = otp.verifyOtp;
      if (otpCode && formValid && !otpError && otpCode !== sendOtpRes?.code) {
        return openSnackbar({
          message: "Invalid OTP. Please check your code and try again.",
          variant: "error",
        });
      }
      payload = {
        code: otpCode,
        vToken: sendOtpRes?.vToken,
      };
      if (!isAlpha) {
        payload = {
          ...payload,
          phone: phoneInfo?.nationalNumber,
        };
      } else {
        payload = {
          ...payload,
          email: username,
        };
      }
    }
    payload = {
      ...payload,
      mode: "WEB",
    };
    if (formValid) {
      if (isSendOtpConds)
      await getAzureToken().catch((err) => {
        const errorRes = ErrorMsg(err);
        openSnackbar({ message: errorRes, variant: "error" });
      });
      SetLoader(true);
      isCounterPage&&SetCounterPage(false);
      saveOrUpdateRequest({
        url: condPostURL,
        method: "POST",
        data: payload,
        queryParams: { apiKey: process.env.REACT_APP_API_KEY },
      })
        .then((response) => {
          if (isSendOtpConds) {
            setSendOtpRes(response?.body);
            openSnackbar({
              message: sendOTPToastrMsg(response?.body?.code),
              variant: "success",
              autoHideDuration:9000
            });
          } else {
            Authorization.login(response?.body);
            if (Authorization.isLoggedIn()) {
              const currentDate = new Date();
              cookie.set('SESSION_BAYAGRI', currentDate);
              cookie.set('SESSION_BAYAGRI_STATUS', 'ACTIVE');
              SetLoader(false);
              dispatch(loginDetails(response?.body));
              openSnackbar({
                message: "User Logged Successfully!",
                variant: "success",
              });
              navigate("/dashboard");
            }
          }

          SetLoader(false);
        })
        .catch((error) => {
          SetLoader(false);
          let msg = error?.response?.data?.body?.message;
          openSnackbar({
            message: msg || "Something Went Wrong",
            variant: "error",
          });
        });
    }
  };

  // Checks Login validation
  const checkValidation = () => {
    let formValid = true;
    const { username } = { ...fields };
    let obj = { ...fields };
    const isSendOtpConds = isSendOtp();
    if (username === "") {
      obj.usernameError = errEmailMsg;
      formValid = false;
    }
    if (!isSendOtpConds && otpCode === "") {
      formValid = false;
      obj.otpError = "OTP code is required.";
    }
    if (!isSendOtpConds && otpCode && otpCode?.length !== 6) {
      formValid = false;
      obj.otpError = "OTP code should be six digits.";
    }
    setFields({ ...obj });
    return formValid;
  };

  const handleGetOtp = (otpValue) => {
    setOtpCode(otpValue);
    let obj = { ...fields };
    if (otpValue) {
      obj.otpError = "";
    }
    setFields({ ...obj });
  };

  const isSendOtp = () => {
    const result= ObjectIsEmpty(sendOtpRes) ? true: false;
    return result;
  };
  const handleResendCode = (event) => {
    event.preventDefault();
    const { username, phoneInfo } = { ...fields };
    let payload = {};
    const { otp } = apiURL;
    let condPostURL = otp.sendOtp;
    // Login Email Address or Mobile Number
    if (loginType === "mobile") {
      payload = {
        phone: phoneInfo?.nationalNumber,
        dialer: `+${phoneInfo?.countryCallingCode}`,
        code: phoneInfo?.countryCode,
      };
    } else {
      payload = {
        email: username,
      };
    }
    payload = {
      ...payload,
      mode: "WEB",
      sendCode: true,
    };
    SetLoader(true);
    saveOrUpdateRequest({
      url: condPostURL,
      method: "POST",
      data: payload,
      queryParams: { apiKey: process.env.REACT_APP_API_KEY },
    })
      .then((response) => {
        setSendOtpRes(response?.body);
        SetLoader(false);
        SetCounterPage(true);
        openSnackbar({
          message: sendOTPToastrMsg(response?.body?.code),
          variant: "success",
          autoHideDuration:9000
        });
      })
      .catch((error) => {
        SetLoader(false);
        let msg = error?.response?.data?.body?.message;
        openSnackbar({
          message: msg || "Something Went Wrong",
          variant: "error",
        });
      });
  };
  const handleRedirectLogin = () => {
    setFields({ ...fields, otpError: "" });
    setSendOtpRes({});
    setOtpCode("");
  };

  const getResetCounter = (count) => {
    if (count === 0) {
      SetCounterPage(false);
    }
  };
  return (
    <Fragment>
      {isLoader && <Loader />}
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          p: 1.5,
          pl: 5,
          gap: 2,
          color: "#585858",
          font: "normal bold 16px Helvetica Neue",
          boxShadow: "0px 0px 12px #1717171C",
        }}
      >
        <Logo />
        <Typography component="h1" variant="h5" letterSpacing={"0.05em"}>
          BayAgri+ Platform
        </Typography>
      </Grid>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7.5}
          sx={{
            backgroundImage: `url(${LoginImg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={4.5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 6,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5" mb={1} letterSpacing={"0.05em"}>
              {isSendOtp() ? "Log in" : "Enter Verification Code"}
            </Typography>
            {!isSendOtp() && (
              <Typography variant="subtitle1" color="#707070" fontWeight={500}>
                {`Enter the six digit code  we sent to your ${
                  loginType === "email" ? "email address" : "mobile number"
                }`}
              </Typography>
            )}
            {!isSendOtp() && (
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                mt={2}
              >
                <Typography> {fields?.username}</Typography>
                <CustomButton
                  label="change"
                  handleClick={handleRedirectLogin}
                ></CustomButton>
              </Box>
            )}
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 5, width: "80%" }}
            >
              {isSendOtp() ? (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Email Address or Mobile Number"
                  placeholder="Enter Your Email Address or Mobile Number"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  helperText={fields.usernameError}
                  onChange={handleChange}
                  error={fields.usernameError ? true : false}
                  sx={textFieldSX}
                  InputProps={{
                    inputComponent: fields.isAlpha ? null : RenderPhone,
                  }}
                  value={fields?.username}
                ></TextField>
              ) : (
                <OtpInput getOtpCode={handleGetOtp} error={fields.otpError} />
              )}
              {/* <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={fields.showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
                helperText={fields.passwordError}
                error={fields.passwordError ? true : false}
                placeholder="Enter Your Password"
                sx={textFieldSX}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {fields.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> */}
              <CustomButton
                label={isSendOtp() ? "Next" : "Verify"}
                variant="contained"
                sx={{
                  mt: 6,
                  mb: 2,
                  color: "#2B6636",
                  borderRadius: "28px",
                  backgroundColor: "#dcedc8",
                  opacity: "1",
                  "&:hover": {
                    backgroundColor: "#dcedc8",
                    color: "#66B512",
                    border: "1.35px solid #66B512",
                  },
                }}
                labelSX={{ fontWeight: 600,letterSpacing:"0.05em" }}
                type="submit"
                fullWidth
              />
              {/* <div className="sso-login-option">
                <span>OR</span>
              </div> */}
              {/* <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
            >
              Log In as SSO
            </Button> */}
              {/* <CustomButton
                label="Log In as SSO"
                sx={{
                  mt: 1,
                  mb: 1,
                  color: "#89D329",
                  borderRadius: "28px",
                  backgroundColor: "#ffffff",
                  opacity: "0.75",
                  border: "1.3px solid #89D329",
                  "&:hover": {
                    backgroundColor: "#dcedc8",
                    color: "#66B512",
                    border: "1.35px solid #66B512",
                  },
                }}
              /> */}
              {!isSendOtp() && (
                <Grid container justifyContent={"center"}>
                  <Grid item display={"flex"}>
                    Didn't get the code? &nbsp;
                    <Typography
                      variant="body1"
                      color="primary"
                      onClick={handleResendCode}
                      sx={{
                        cursor: "pointer",
                        ...(isCounterPage && {
                          pointerEvents: "none",
                          color: "lightgray",
                        }),
                      }}
                    >
                      {/* {`Change your ${loginType === "email" ? "email address" : "mobile number"}`} */}
                      Resend Code
                    </Typography>
                  </Grid>
                  {isCounterPage && (
                    <Counter getResetCounter={getResetCounter} />
                  )}
                </Grid>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Login;
