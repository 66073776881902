import * as React from 'react';
import { useState } from "react";
import { Box } from "@mui/material";
import ActivitiesAccounts from "../ActivitiesAccounts";
import DocumentCreate from "../../Contracts/CreateContracts/DocumentCreate"
const TITLEDOCUMENT = 'Payout Document';

export const PayoutView = () => {
    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState({});

    const closeCreate = () => {
        setOpen(false)
      }

   const openDocument=(text,obj)=>{
      const sch = {
        itmObj:{...obj?.listObj}
      }
      setRowData(sch)
      setOpen(true)

    }
    
    return(<Box>
    {open&&<DocumentCreate
            rowData={rowData}
            docFor='PAYOUT'
            close={() => closeCreate()}
            set={open}
            title={TITLEDOCUMENT}
     />}
    <ActivitiesAccounts openDocument = {(text,obj)=>openDocument(text,obj)} from={'PO'}/>
    </Box>
    )
}