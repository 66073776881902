import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  percentageData: [],
  percentageDataLoading: false,
  percentageDataError: false,
};

const assessmentPercentageRedux = createSlice({
  name: "assessmentPercentageRedux",
  initialState,
  reducers: {
    getAssessmentPercentageData(state) {
      state.percentageDataLoading = true;
      state.percentageData = [];
    },
    getAssessmentPercentageData_successfull(state, action) {
      const data = action.payload?.body;
      state.percentageData = data;
      state.percentageDataLoading = false;
    },
    getAssessmentPercentageData_error(state) {
      state.percentageDataError = true;
    },

    getAssessmentPercentageData_reset(state) {
      return {
        ...state,
        percentageData: [],
        percentageDataLoading: false,
        percentageDataError: false,
      };
    },
  },
});

export const {
  getAssessmentPercentageData,
  getAssessmentPercentageData_successfull,
  getAssessmentPercentageData_error,
  getAssessmentPercentageData_reset,
} = assessmentPercentageRedux.actions;

export default assessmentPercentageRedux.reducer;
