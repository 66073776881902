import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  tableData: [],
  tableDataLoading: false,
  tableDataError: false,
  tableDataUpdated:[],
  updatedRows: [],
  previousRows:[]
};

const assessmentRedux = createSlice({
  name: "assessmentRedux",
  initialState,
  reducers: {
    getAssessmentData(state) {
      state.tableDataLoading = true;
      state.tableData = [];
    },
    getAssessmentData_successful(state, action) {
      const data = action.payload?.body;
      state.tableData = data;
      state.tableDataLoading = false;
      if (data?.contract?.activitytargets?.length > 0) {
        const rowsData = data?.contract?.activitytargets.map((r) => {
          if (!r.assignedlevel) {
            return (r = { ...r, assignedlevel: r?.currentlevel });
          }
          return r;
        });
        
        state.updatedRows = rowsData;
        state.previousRows = rowsData;
        state.tableDataUpdated = rowsData;
      }
    },
    getAssessmentData_error(state) {
      state.tableDataError = true;
    },
    updateAssessmentData(state, action) {
      return {
        ...state,
        updatedRows: action.payload,
        tableDataLoading: false,
        tableDataError: false,
      };
    },
    previousAssessmentData(state, action) {
      return {
        ...state,
        previousRows: action.payload,
        tableDataLoading: false,
        tableDataError: false,
      };
    },
    getAssessmentData_reset(state) {
      return {
        ...state,
        tableData: [],
        tableDataLoading: false,
        tableDataError: false,
        updatedRows: [],
        previousRows:[],
        tableDataUpdated:[]
      };
    },
  },
});

export const { getAssessmentData, getAssessmentData_successful, getAssessmentData_error, getAssessmentData_reset, updateAssessmentData,previousAssessmentData } =
  assessmentRedux.actions;

export default assessmentRedux.reducer;
