import { useState } from "react";
import CommonTable from "../../../../components/ui/CommonTable";
import { Box } from "@mui/material";
import { CustomButton } from "../../../../components/ui/Button";
import MainCard from "../../../../components/MainCard";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAssessmentPercentageData } from "../../../../saga/saga/assessmentPercentageSaga";
import { useEffect } from "react";
import { apiURL } from "../../../../services/apiUrlConfig";
import util from "util";
import Authorization from "../../../../utils/authorization";
import DocumentCreate from "../../Contracts/CreateContracts/DocumentCreate"

const TITLEDOCUMENT = 'Payout Document';

const headerCells = [
  {
    id: "activityname",
    label: "Activity",
    numeric: false,
    disablePadding: true,
    minWidth: "auto",
    isInputType: "",
    isLast: false,
    userInteration: false,
    isMaxWidthApply: false,
  },
  {
    id: "conditionaldiscountpercent",
    label: "Assigned Percentage (%)",
    numeric: true,
    disablePadding: true,
    minWidth: "auto",
    isInputType: "",
    isLast: false,
    userInteration: false,
    isMaxWidthApply: false,
  },
  {
    id: "achievedconditionaldiscountpercent",
    label: "Achieved Percentage (%)",
    numeric: true,
    disablePadding: true,
    minWidth: "auto",
    isInputType: "",
    isLast: false,
    userInteration: false,
    isMaxWidthApply: false,
  },
];


const AssessmentPercentage = () => {
  const tableResponse = useSelector((state) => state.assessmentPercentageSaga?.percentageData);
  const tableResponseLoading = useSelector((state) => state.assessmentPercentageSaga?.percentageDataLoading);
  const { viewContractID } = useSelector((state) => state.common);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  //redux
  const dispatch = useDispatch();
  //const
  const authUser = Authorization.getAuthUser();
  const tableData = tableResponse?.contract?.activitytargets;

  useEffect(() => {
      getLoadData();
  }, []);

  const getLoadData = () => {
    const { ppp } = apiURL;
    const condUrl = util.format(ppp.getAssessment, authUser?.id, viewContractID);
    const queryParams = `?type=PERCENT`;
    dispatch(getAssessmentPercentageData(condUrl + queryParams));
  };

  const redirectAssessmentPage = () => {
    const titlepath = `/tracking/activities/assessment`;
    navigate(titlepath, {
      state: {
        obj: ["Assessment"],
        title: { ...location?.state?.title, [titlepath]: "ASSESSMENT" },
        prevCall:true
      },
    });
  };

  const openCreate = () => {
    setOpen(true)
  }
  const closeCreate = () => {
    setOpen(false)
  }
  const sch = {
    itmObj:{...tableResponse?.contract}
  }
  return (
    <Box>
       {open&&<DocumentCreate
        refreshAction={(saved) => getLoadData(saved)}
        rowData={sch}
        docFor='PAYOUT'
        close={() => closeCreate()}
        set={open}
        title={TITLEDOCUMENT}
      />}
      <MainCard elevation={0} border={false} content={true}>
        <CommonTable
          statusLoad={tableResponseLoading}
          rows={tableData}
          headCells={headerCells}
          getFilterData={() => {}}
          getAPICall={() => {}}
          isTableFooter={true}
          footerList={[
            { value: null, label: "Total Discount" },
            { value: tableResponse?.contract?.totaldiscountpercent, label: null },
            { value: tableResponse?.contract?.totalachieveddiscountpercent, label: null },
          ]}
        ></CommonTable>
        <Box display={"flex"} justifyContent={"flex-end"} m={1}>
          <CustomButton
            label="Previous"
            sx={{
              color: "rgb(0, 145, 223)",
              borderRadius: "28px",
              backgroundColor: "#fff",
              opacity: "1",
              width: "100px",
              marginLeft: "10px",
              height: "32px",
              border: "1px solid rgb(0, 145, 223)",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
            handleClick={redirectAssessmentPage}
            labelClassName={"label"}
            labelSX={{
              fontSize: 12,
              fontWeight: "bold",
              letterSpacing: 0.3,
            }}
          ></CustomButton>
          <CustomButton
            label="Payout"
            title={sch?.itmObj?.ispayoutdone?'view payout document':'create payout document'}
            sx={{
              color: "#fff",
              borderRadius: "28px",
              backgroundColor: "rgb(0, 145, 223)",
              opacity: "1",
              "&:hover": {
                backgroundColor: "#0D47A1",
              },
              width: "110px",
              marginLeft: "10px",
              height: "32px",
            }}
            handleClick={openCreate}
            className="cus-btn-arrow"
            labelClassName={"label"}
            labelSX={{
              fontSize: 12,
              fontWeight: "bold",
              letterSpacing: 0.3,
            }}
          ></CustomButton>
        </Box>
      </MainCard>
    </Box>
  );
};

export default AssessmentPercentage;
