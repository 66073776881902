import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { getActivities } from "../../../saga/saga/activitiesSaga";
import Authorization from "../../../utils/authorization";
import util from "util";
import { apiURL } from "../../../services/apiUrlConfig";
import { useDispatch, useSelector } from "react-redux";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const dataColor = {
  "Order Management": "#DE5A82",
  "Payment Management": "#2E6F40",
  "Forecast Information": "#A9D677",
  "Market Intelligence": "#5595A8",
  "Demand Generation Trials": "#96A8AE",
  "Offline Marketing Activities": "#624963",
  "Online Marketing Activities": "#2B6636",
  "Long Term Business Planning": "#CFFDC",
  "Sales Volume": "",
  "POG Delivery": "",
  "Performance Trials": "#707070"
}

const dataPointsColor = [
  { id: 1, label: "Order Management", backgroundColor: "#DE5A82" },
  { id: 2, label: "Payment Management", backgroundColor: "#2E6F40" },
  { id: 3, label: "Forecast Information", backgroundColor: "#A9D677" },
  { id: 4, label: "Market Intelligence", backgroundColor: "#5595A8" },
  { id: 5, label: "Demand Generation Trials", backgroundColor: "#96A8AE" },
  {
    id: 6,
    label: "Offline Marketing Activities",
    backgroundColor: "#624963",
  },
  {
    id: 7,
    label: "Online Marketing Activities",
    backgroundColor: "#2B6636",
  },
  {
    id: 8,
    label: "Long Term Business Planning",
    backgroundColor: "#CFFDC",
  },
  { id: 12, label: "Performance Trials", backgroundColor: "#707070" },
];

const response = {
  status: 200,
  message: "Fetched Successfully",
  body: {
    accounts: [
      {
        contractid: 10,
        name: "07G02340-CETIN YAGLI",
        averagepercent: 26,
        activitytargets: [
          {
            id: 1,
            activityname: "Order Management",
            completedpercent: 20,
          },
          {
            id: 2,
            activityname: "Payment Management",
            completedpercent: 10,
          },
          {
            id: 3,
            activityname: "Forecast Information",
            completedpercent: 30,
          },
          {
            id: 4,
            activityname: "Market Intelligence",
            completedpercent: 44,
          },
        ],
      },
      {
        contractid: 11,
        name: "06T2340-KERIN NKGLI",
        averagepercent: 20,
        activitytargets: [
          {
            id: 1,
            activityname: "Order Management",
            completedpercent: 10,
          },
          {
            id: 2,
            activityname: "Payment Management",
            completedpercent: 20,
          },
          {
            id: 3,
            activityname: "Forecast Information",
            completedpercent: 10,
          },
          {
            id: 4,
            activityname: "Market Intelligence",
            completedpercent: 40,
          },
          {
            id: 5,
            activityname: "Demand Generation",
            completedpercent: 20,
          },
        ],
      },
      {
        contractid: 39,
        name: "01J89876-Test",
        averagepercent: 40,
        activitytargets: [
          {
            id: 1,
            activityname: "Order Management",
            completedpercent: 30,
          },
          {
            id: 2,
            activityname: "Payment Management",
            completedpercent: 40,
          },
          {
            id: 3,
            activityname: "Forecast Information",
            completedpercent: 40,
          },
          {
            id: 4,
            activityname: "Market Intelligence",
            completedpercent: 74,
          },
        ],
      },
      {
        contractid: 65,
        name: "04B99987-User",
        averagepercent: 35,
        activitytargets: [
          {
            id: 1,
            activityname: "Order Management",
            completedpercent: 30,
          },
          {
            id: 2,
            activityname: "Payment Management",
            completedpercent: 19,
          },
          {
            id: 3,
            activityname: "Forecast Information",
            completedpercent: 60,
          },
          {
            id: 4,
            activityname: "Market Intelligence",
            completedpercent: 30,
          },
          {
            id: 5,
            activityname: "Demand Generation",
            completedpercent: 50,
          },
        ],
      },
    ],
  },
};
function processAccountDataOptimized(accounts, actTypes) {
  if (!accounts || accounts?.length === 0) {
    return { labels: [], datasets: {} };
  }

  const activityData = {};
  const labels = [];
  const lableIndex = {}
  const activityTypeIds = [];
  const activityTypeIndex = {};
  const dSets = []
  for (const act of actTypes) {
    if (act?.name) {
      activityTypeIndex[act.name] = activityTypeIds.length
      dSets[activityTypeIds.length] = {
        label: act.name,
        data: [],
        borderRadius: 10,
        backgroundColor: dataColor[act.name] || ""
      }
      activityTypeIds.push(act.name);
    }
  }
  for (const account of accounts) {
    if (account?.name) {
      lableIndex[account.name] = labels.length
      labels.push(account.name);
    }

    if (account?.activitytargets) {
      for (const activityTarget of account.activitytargets) {
        if (activityTypeIndex[activityTarget?.activityname] != undefined && dSets[activityTypeIndex[activityTarget?.activityname]] && dSets[activityTypeIndex[activityTarget?.activityname]]["data"]) {
          dSets[activityTypeIndex[activityTarget?.activityname]]["data"][lableIndex[account.name]] = activityTarget?.completedpercent;
        }
        // const activityNameLower = activityTarget?.activityname;
        // const completedPercent = activityTarget?.completedpercent;

        // if (activityTypeIds.includes(activityNameLower)) {
        //   if (!activityData[activityNameLower]) {
        //     activityData[activityNameLower] = [];
        //   }
        //   if (completedPercent !== undefined) {
        //     activityData[activityNameLower].push(completedPercent);
        //   }
        // }
      }
    }
  }
  // const getColorForLabel = (label, dataPointsColor) =>
  //   dataPointsColor?.length ? dataPointsColor.find((col) => col.name === label)?.backgroundColor : undefined;
  // const datasets = Object.entries(activityData).map(([label, data]) => ({
  //   label,
  //   data: [...data],
  //   borderRadius: 10,
  //   backgroundColor: getColorForLabel(label, actTypes),
  // }));

  return { labels, datasets: dSets };
}

export default function BarChart({ filters }) {
  const [resData, setResData] = useState([response]);
  const [barData, setBarData] = useState({ labels: [], datasets: [] });
  const [selectedLegend, setSelectedLegend] = useState(null);
  const [actTypes, setActTypes] = useState([]);

  const getApi = apiURL.ppp.getActivities ?? "";
  const userDetaisl = Authorization.authUser;
  const activitisApi = util.format(getApi, userDetaisl.id);
  const dispatch = useDispatch();
  const activitiesList = useSelector((state) => state.activitiesSaga.activitiesList);

  useEffect(() => {
    dispatch(getActivities(activitisApi));
  }, []);

  useEffect(() => {
    const temp = [];
    for (let i = 0; i < activitiesList?.length; i++) {
      for (let j = 0; j < dataPointsColor?.length; j++) {
        if (activitiesList[i].id === dataPointsColor[j].id) {
          const obj = { ...activitiesList[i], backgroundColor: dataPointsColor[j].backgroundColor };
          temp.push(obj);
        }
      }
    }
    setActTypes(temp);
  }, [activitiesList]);

  useEffect(() => {
    if (actTypes?.length) {
      const result = processAccountDataOptimized(acccounts, actTypes);
      setBarData(result);
    }
  }, [actTypes]);

  useEffect(() => {
    if (filters?.sortBy) {
      const tempAccounts = [...acccounts];
      let resultConds = {};
      if (selectedLegend) {
        const updated = sortByActivityTypes();
        resultConds = { ...updated };
      } else {
        tempAccounts?.length &&
          tempAccounts.sort((a, b) => (filters?.sortBy === "asc" ? a.averagepercent - b.averagepercent : b.averagepercent - a.averagepercent));
        const result = processAccountDataOptimized(tempAccounts, actTypes);

        resultConds = { ...result };
      }
      setBarData(resultConds);
    }
  }, [filters, selectedLegend]);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: function (value) {
            return value + "%"; // Add percentage symbol to y-axis ticks
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        onClick: (event, legendItem, legend) => {
          if (selectedLegend === legendItem.text) {
            setSelectedLegend(null); // Deselect if already selected
          } else {
            setSelectedLegend(legendItem.text);
          }
        },
        labels: {
          generateLabels: (chart) => {
            const original = ChartJS.defaults.plugins?.legend?.labels?.generateLabels(chart);
            return original.map((label) => {
              if (label.text === selectedLegend) {
                return {
                  ...label,
                  fontColor: "#1677ff",
                  strokeStyle: "#add8e6", // Border color
                  lineWidth: 1.5, // Border width
                  borderRadius: 5, // Optional: rounded corners
                  padding: 5, // Optional: padding around text
                };
              }
              return label;
            });
          },
        },
      },
      title: { display: true, text: "" },
    },
  };

  const acccounts = response?.body?.accounts;

 

  const filteredData = {
    ...barData,
    datasets: barData.datasets.map((dataset) => {
      if (selectedLegend && dataset.label !== selectedLegend) {
        return {
          ...dataset,
          data: dataset.data.map(() => null), // Hide other datasets
        };
      }
      return dataset;
    }),
  };
  // console.log({ filteredData });
  const sortByActivityTypes = (acccounts) => {
    const filteredData = {
      ...barData,
      datasets: barData.datasets.map((dataset) => {
        if (selectedLegend && dataset.label === selectedLegend) {
          return {
            ...dataset,
            data: dataset.data.sort((a, b) => (filters?.sortBy === "asc" ? a - b : b - a)),
          };
        }
        return dataset;
      }),
    };
    return filteredData;
  };
  // const chartWidth = barData.labels.length > 4 ? 1000 : 700;
  return <Bar data={filteredData} options={options} />;
}
