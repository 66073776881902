/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select, Skeleton, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { getRequest, saveOrUpdateRequest } from '../../services/apiUtils';
import { openSnackbar } from '../../components/ui/Toastr';




const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#00617F",
        color: theme.palette.common.white,
        paddingTop: 2,
        paddingBottom: 2,
        fontSize: 12,
        fontWeight: 100,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        paddingTop: 10,
        paddingBottom: 9,
        fontWeight: 100,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "white",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const headCells = [
    { name: 'Account Name', id: '1' },
    { name: 'Phone', id: '2' },
    { name: 'Email', id: '3' },
    { name: 'Account Record Type', id: '4' },
    { name: 'Market Value', id: '5' },
    { name: 'Total Sales', id: '6' },
    { name: 'Inactive Account', id: '7' },
]


const SfdcAccounts = () => {
    const [rows, setRows] = useState([{}])
    const [currentRow, setCurrentRow] = useState(null)
    // const [editedData, setEditedData] = useState({
    //     "sfid": "",
    //     "name": "",
    //     "phone": "",
    //     "email": "",
    //     "recordytype": "",
    //     "gc_sap_id__c": "",
    //     "marketvalue": "",
    //     "totalsales": "",
    //     "inactive_account__c": true,
    // })

    const [name, setName] = useState(null)
    const [phone, setPhone] = useState(null)
    const [sfid, setSfid] = useState(null)
    const [email, setEmail] = useState(null)
    const [recordType, setRecordType] = useState("")
    const [sapid, setSapid] = useState(null)
    const [marketValue, setMarketValue] = useState(null)
    const [totalSales, setTotalSales] = useState(null)
    const [inactiveAcc, setInactiveAcc] = useState(false)
    const [deleteRowID, setDeleteRowID] = useState(null)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)


    const [statusLoad, setStatusLoad] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [open, setOpen] = useState(false)
    const [createOpen, setCreateOpen] = useState(false)
    const [errors, setErrors] = useState(
        {
            name: false,
            sfid: false,
            phone: false,
            email: false,
            recordType: false,
            sapid: false,
            marketValue: false,
            totalSales: false,
            inactiveAcc: false
        }
    )

    const handleClickOpen = (row) => {

        setCurrentRow(row)
        setName(row.name)
        setSfid(row.sfid)
        setPhone(row.phone)
        setEmail(row.email)
        setRecordType(row.recordytype)
        setSapid(row.gc_sap_id__c)
        setMarketValue(row.marketvalue)
        setTotalSales(row.totalsales)
        setInactiveAcc(row.inactive_account__c)
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteOpen = (id) => {
        setDeleteRowID(id)
        setDeleteOpen(true)

    }

    const handleCreateOpen = () => {
        setName("")
        setPhone("")
        setSfid("")
        setEmail("")
        setRecordType("")
        setSapid("")
        setMarketValue("")
        setTotalSales("")
        setInactiveAcc(false)

        setErrors({
            name: false,
            phone: false,
            email: false,
            recordType: false,
            sapid: false,
            marketValue: false,
            totalSales: false,
            inactiveAcc: false
        })

        setCreateOpen(true);
    }

    const handleCreateClose = () => {
        setCreateOpen(false)
    }

    const handleDeleteClose = () => {
        setDeleteOpen(false)
    }

    const handleEditSubmit = () => {
        let newErrors = {
            name: name === "",
            phone: phone === "",
            email: email === "",
            recordType: recordType === "",
            sapid: sapid === "",
            marketValue: marketValue === "",
            totalSales: totalSales === "",
            inactiveAcc: inactiveAcc === ""
        }
        setErrors(newErrors)

        if (Object.values(newErrors).some((error) => error)) {
            return
        }
        const updatedRow = {
            "name": name.toString(),
            "phone": phone.toString(),
            "email": email.toString(),
            "recordtype": `${recordType}`,
            "marketvalue": marketValue.toString(),
            "totalsales": totalSales.toString(),
            "inactive_account__c": inactiveAcc,
        }

        saveOrUpdateRequest({
            url: `account/create_sfdc_account?`,
            method: "POST",
            data: updatedRow,
            queryParams: { apiKey: process.env.REACT_APP_API_KEY },
        })
            .then((response) => {
                console.log(response)
                setRows((prev) => prev.map((row) => row.sfid === currentRow.sfid ? { ...row, ...updatedRow } : row))


            })
            .catch((error) => {
                let msg = error?.response?.data?.body?.message;
                openSnackbar({
                    message: msg || "Something Went Wrong",
                    variant: "error",
                });
            });
        handleClose();
    }

    const handleDelete = async () => {
        const id = deleteRowID
        const updatedRow = rows.filter((row) => row.sfid !== id)
        setRows(updatedRow)
        rows.map((row) => {
            if (row.sfid === id) {
                // const data = {"sfid":row.sfid,"phone":row.phone, "isdeleted":true}
                const data = {
                    "sfid":row.sfid,
                    "name": row.name,
                    "phone": `${row.phone}`,
                    "email": `${row.email}`,
                    "isdeleted":true
                }
                console.log(row.sfid)
                saveOrUpdateRequest({
                    url: `account/create_sfdc_account?`,
                    method: "POST",
                    data: data,
                    queryParams: { apiKey: process.env.REACT_APP_API_KEY },
                })
                    .then((response) => {
                        console.log(response)
                        openSnackbar({
                            message: response.message || "Deleted Successfully",
                            variant: "success",
                        });
                    })
                    .catch((error) => {
                        let msg = error?.response?.data?.body?.message;
                        openSnackbar({
                            message: msg || "Something Went Wrong",
                            variant: "error",
                        });
                    });
            }
        })

        setDeleteOpen(false)
    }

    const handleCreateSubmit = () => {

        const id = rows.length
        console.log(id)
        let newErrors = {
            name: name === "",
            phone: phone === "",
            email: email === "",
            recordType: recordType === "",
            marketValue: marketValue === "",
            totalSales: totalSales === "",
            inactiveAcc: inactiveAcc === ""
        }
        setErrors(newErrors)

        if (Object.values(newErrors).some((error) => error)) {
            return
        }
        const newRow = {
            "name": name,
            "phone": phone.toString(),
            "email": email.toString(),
            "recordtype": `${recordType}`,
            "marketvalue": marketValue.toString(),
            "totalsales": totalSales.toString(),
            "inactive_account__c": inactiveAcc,
        }
        saveOrUpdateRequest({
            url: `account/create_sfdc_account?`,
            method: "POST",
            data: newRow,
            queryParams: { apiKey: process.env.REACT_APP_API_KEY },
        })
            .then((response) => {
                console.log(response)
                setRows([newRow, ...rows,])
                console.log(newRow)
            })
            .catch((error) => {
                console.log(error)
                let msg = error?.response?.data?.body?.message;
                openSnackbar({
                    message: msg || "Something Went Wrong",
                    variant: "error",
                });
            });

        setName(null)
        setPhone(null)
        setSfid(null)
        setEmail(null)
        setRecordType("")
        setSapid(null)
        setMarketValue(null)
        setTotalSales(null)
        setInactiveAcc(false)
        setCreateOpen(false)
    }

    const handleChangePage = (_, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10))
        setPage(0)
    }



    const getAccData = () => {
        setStatusLoad(true)
        getRequest({
            url: `account/get_sfdc_accounts?apiKey=${process.env.REACT_APP_API_KEY}`,
        }).then((response) => {
            const result = response?.body;
            if (result?.length > 0) {
                const arr = result
                let headerLength
                // arr.map((obj)=>{
                //     headerLength = Object.keys(obj).length
                // })

                // console.log(headerLength)
                // console.log('keys ', Object.keys(result.map((item)=>(item))));
                // console.log('get requ', result);
                setRows(result)
            } else {
                setStatusLoad(false)
            }
        })
            .catch((error) => {
                openSnackbar({ message: "No Data Found!", variant: "error" });
                setStatusLoad(false)
            });
    }

    useEffect(() => {
        getAccData();
    }, [])

    const paginatedRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)


    const LoadWhile = () => {
        return statusLoad ? (
            [...Array(4)].map((item, itemIndex) => {
                return (
                    <TableRow key={itemIndex}>
                        <TableCell
                            colSpan={headCells?.length + 1}
                            sx={{ textAlign: "center" }}
                            style={{
                                borderBottom: "0px solid transparent",
                                paddingBottom: 0.5,
                                paddingRight: 2,
                                paddingLeft: 0,
                            }}
                        >
                            <Skeleton
                                animation="wave"
                                variant="rectangular"
                                sx={{ height: 60, width: "100%", backgroundColor: "#F2F2F2" }}
                            />
                        </TableCell>
                    </TableRow>
                );
            })
        ) : (
            <TableRow>
                <TableCell colSpan={headCells?.length + 1} sx={{ textAlign: "center" }}>
                    {" "}
                    No records found!
                </TableCell>
            </TableRow>
        );
    };




    return (
        <div style={{ margin: '20px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <Typography sx={{ fontWeight: 'bold' }}>List of accounts</Typography>
                <Button sx={{ backgroundColor: '#00617f' }} variant='contained' onClick={handleCreateOpen}>Create Account</Button>
            </Box>
            <TableContainer style={{ overflow: "auto", height: 380 }}>
                <Table sx={{ maxHeight: 380 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: "#00617F" }}>
                            {/* {headCells?.length > 0 && <StyledTableCell align="left">Accounts</StyledTableCell>} */}
                            {headCells?.length > 0 &&
                                headCells.map((itm, index) => {
                                    return <StyledTableCell key={index} align="left">{itm.name}</StyledTableCell>;
                                })}
                            {headCells?.length > 0 && <StyledTableCell align="left">Actions</StyledTableCell>}
                            {/* {headCells?.length > 0 && <StyledTableCell align="left"></StyledTableCell>} */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedRows?.length > 0 && headCells?.length > 0 ? (
                            paginatedRows.map((row, index) => {
                                return (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell
                                            style={{ textWrap: row.name?.length >= 26 ? "balance" : "nowrap", paddingRight: 0 }}
                                            component="th"
                                            scope="row"
                                        >
                                            {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            style={{ textWrap: row.account?.length >= 26 ? "balance" : "nowrap", paddingRight: 0 }}
                                            component="th"
                                            scope="row"
                                        >
                                            {row.phone}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            style={{ textWrap: row.account?.length >= 26 ? "balance" : "nowrap", paddingRight: 0 }}
                                            component="th"
                                            scope="row"
                                        >
                                            {row.email}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            style={{ textWrap: row.account?.length >= 26 ? "balance" : "nowrap", paddingRight: 0 }}
                                            component="th"
                                            scope="row"
                                        >

                                            {row.recordytype}


                                        </StyledTableCell>
                                        {/* <StyledTableCell
                                            style={{ textWrap: row.account?.length >= 26 ? "balance" : "nowrap", paddingRight: 0 }}
                                            component="th"
                                            scope="row"
                                        >
                                            {row.gc_sap_id__c}
                                        </StyledTableCell> */}
                                        <StyledTableCell
                                            style={{ textWrap: row.account?.length >= 26 ? "balance" : "nowrap", paddingRight: 0 }}
                                            component="th"
                                            scope="row"
                                        >
                                            {row.marketvalue}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            style={{ textWrap: row.account?.length >= 26 ? "balance" : "nowrap", paddingRight: 0 }}
                                            component="th"
                                            scope="row"
                                        >
                                            {row.totalsales}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            style={{ textWrap: row.account?.length >= 26 ? "balance" : "nowrap", paddingRight: 0 }}
                                            component="th"
                                            scope="row"
                                        >
                                            <Checkbox readOnly checked={row.inactive_account__c} />

                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="left"
                                            style={{ textWrap: "nowrap" }}
                                        >
                                            <EditIcon sx={{ cursor: 'pointer', marginRight: '20px' }} fontSize='small' color='black' onClick={() => handleClickOpen(row)} />
                                            <DeleteIcon fontSize='small' sx={{ cursor: 'pointer' }} onClick={() => handleDeleteOpen(row.sfid)} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })
                        ) : (
                            <LoadWhile />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[3, 5, 10,{ label: 'All', value: -1 }]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}

            />


            {/* edit dialog */}
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Edit Account</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        id="name"
                        type="text"
                        label="Account name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        variant="standard"
                        error={errors.name}
                    />
                    <TextField
                        required
                        label="Phone"
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        fullWidth
                        variant="standard"
                        error={errors.phone}
                    />
                    <TextField
                        required
                        label="Email"
                        value={email}
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        variant="standard"
                        error={errors.email}
                    />
                    <FormControl fullWidth>
                        <Typography sx={{ marginTop: '5px', marginBottom: '2px', color: 'grey' }}>Account Record Type</Typography>
                        <Select
                            variant='standard'
                            value={recordType}
                            onChange={(e) => setRecordType(e.target.value)}
                            error={errors.recordType}
                        >
                            <MenuItem value="Direct Account">
                                Direct Account
                            </MenuItem>
                            <MenuItem value="Indirect Account">
                                Indirect Account
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {/* <TextField
                        required
                        label="SAP ID"
                        value={sapid ? sapid : ''}
                        onChange={(e) => setSapid(e.target.value)}
                        fullWidth
                        variant="standard"
                        error={errors.sapid}
                    /> */}
                    <TextField
                        required
                        label="Market Value"
                        value={marketValue}
                        type="number"
                        onChange={(e) => setMarketValue(e.target.value)}
                        fullWidth
                        variant="standard"
                        error={errors.marketValue}
                    />
                    <TextField
                        required
                        label="Total Sales"
                        type="number"
                        value={totalSales}
                        onChange={(e) => setTotalSales(e.target.value)}
                        fullWidth
                        variant="standard"
                        error={errors.totalSales}
                    />
                    <FormGroup row>
                        <FormControlLabel labelPlacement="start" sx={{ marginLeft: '0px' }} control={<Checkbox checked={inactiveAcc} onChange={(e) => setInactiveAcc(e.target.checked)} />} label="Inactive Account" />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleEditSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>

            {/* delete dialog */}
            <Dialog
                open={deleteOpen}
                onClose={handleDeleteClose}
            >
                <DialogTitle>Delete Account</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this account?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose}>No</Button>
                    <Button onClick={handleDelete}>Yes</Button>
                </DialogActions>
            </Dialog>

            {/* create dialog */}
            <Dialog
                open={createOpen}
                onClose={handleCreateClose}

            >
                <DialogTitle>Create Account</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        id="name"
                        type="text"
                        label="Account name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        variant="standard"
                        error={errors.name}
                    />
                    {/* <TextField
                        autoFocus
                        required
                        id="sfif"
                        type="text"
                        label="Sfid"
                        value={sfid}
                        onChange={(e) => setSfid(e.target.value)}
                        fullWidth
                        variant="standard"
                        error={errors.sfid}
                    /> */}
                    <TextField
                        required
                        label="Phone"
                        type="number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        fullWidth
                        variant="standard"
                        error={errors.phone}
                    />
                    <TextField
                        required
                        label="Email"
                        value={email}
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        variant="standard"
                        error={errors.email}
                    />
                    <FormControl fullWidth>
                        <Typography sx={{ marginTop: '5px', marginBottom: '2px', color: 'grey' }}>Account Record Type*</Typography>
                        <Select
                            required
                            variant='standard'
                            value={recordType}
                            onChange={(e) => setRecordType(e.target.value)}
                            error={errors.recordType}
                        >
                            <MenuItem value="Direct Account">
                                Direct Account
                            </MenuItem>
                            <MenuItem value="Indirect Account">
                                Indirect Account
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {/* <TextField
                        required
                        label="SAP ID"
                        type="number"
                        value={sapid}
                        onChange={(e) => setSapid(e.target.value)}
                        fullWidth
                        variant="standard"
                        error={errors.sapid}
                    /> */}
                    <TextField
                        required
                        label="Market Value"
                        type="number"
                        value={marketValue}
                        onChange={(e) => setMarketValue(e.target.value)}
                        fullWidth
                        variant="standard"
                        error={errors.marketValue}
                    />
                    <TextField
                        required
                        label="Total Sales"
                        type="number"
                        value={totalSales}
                        onChange={(e) => setTotalSales(e.target.value)}
                        fullWidth
                        variant="standard"
                        error={errors.totalSales}
                    />
                    <FormGroup row>
                        <FormControlLabel sx={{ marginLeft: '0px' }} labelPlacement="start" control={<Checkbox required checked={inactiveAcc} onChange={(e) => setInactiveAcc(e.target.checked)} />} label="Inactive Account" />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateClose}>Cancel</Button>
                    <Button onClick={handleCreateSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}



export default SfdcAccounts


