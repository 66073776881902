/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";

import usePagination from "../../hooks/usePagination";
import CusPagination from "./Pagination";
import dayjs from "dayjs";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { updateAssessmentData } from "../../saga/saga/assessmentTableSaga";
import { useDispatch } from "react-redux";
import FormControl from "@mui/material/FormControl";
import TableFooter from "@mui/material/TableFooter";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00617F",
    color: "#FFFFFF",
    "&:last-child": {
      borderTopRightRadius: "4px",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "#FFFFFF",
  },
  [`&.${tableCellClasses.root}`]: {
    padding: 8,
    border: "none",
  },
  [`&.${tableCellClasses.footer}`]: {
    backgroundColor: "#F5F5F5",
    color: "#585858",
    lineHeight: "1.4rem",
  },
}));

function CustomTableHead(props) {
  // eslint-disable-next-line no-unused-vars
  const { headCells } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells?.length > 0 &&
          headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={"left"}
              style={{
                width: headCell?.minWidth,
                fontSize: 12,
                fontWeight: 400,
                letterSpacing: 0.3,
              }}
            >
              {headCell.label}
            </StyledTableCell>
          ))}
        <StyledTableCell></StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

function CusTableRow(props) {
  return <TableRow style={{ marginTop: "-10px" }}>{props?.children}</TableRow>;
}

function RenderInputTypes({ type, value, options, onChange, isError, handleBlur, isMaxWidthApply, cusInputProps, hcObj }) {
  const condClassN = hcObj?.isReadOnly ? "readonly" : "";
  const readOnlyStyles = {
    opacity: condClassN ? 0.75 : 1,
    "& .MuiOutlinedInput-input": { cursor: condClassN ? "not-allowed" : type === "dropdown" ? "pointer" : "text" },
  };
  switch (type) {
    case "input":
      return (
        <OutlinedInput
          type="text"
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
          name=""
          error={isError}
          fullWidth={isMaxWidthApply}
          inputProps={cusInputProps}
          readOnly={hcObj?.isReadOnly}
          sx={readOnlyStyles}
        />
      );
    case "dropdown":
      return (
        <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
          <Select value={value} onChange={onChange} onBlur={handleBlur} readOnly={hcObj?.isReadOnly} sx={readOnlyStyles}>
            {options?.length > 0 &&
              options?.map((option) => (
                <MenuItem key={option.value} value={option?.value}>
                  {option?.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      );
    default:
      return false;
  }
}
function Row(props) {
  // eslint-disable-next-line no-unused-vars
  const { row, headCells, rowIndex, handleChange, handleBlur } = props;
  return (
    <Fragment>
      <CusTableRow>
        {headCells?.length > 0 &&
          headCells.map((hc) => {
            return (
              <StyledTableCell
                align={"left"}
                style={{
                  fontSize: 12,
                  width: hc?.minWidth,
                }}
                key={hc?.id}
              >
                {hc?.userInteration === true ? (
                  <RenderInputTypes
                    type={hc?.isInputType}
                    value={row[hc?.id]}
                    options={hc?.options}
                    onChange={(event) => handleChange(event, rowIndex, hc?.id)}
                    handleBlur={(event) => handleBlur(event, rowIndex, hc?.id)}
                    isError={row[`${hc?.id}_Error`]}
                    isMaxWidthApply={hc?.isMaxWidthApply}
                    cusInputProps={hc?.inputProps}
                    hcObj={hc}
                  ></RenderInputTypes>
                ) : hc?.type === "date" ? (
                  row[hc?.id] ? (
                    dayjs(row[hc?.id]).format("DD/MM/YYYY")
                  ) : (
                    ""
                  )
                ) : (
                  row[hc?.id]
                )}
              </StyledTableCell>
            );
          })}
      </CusTableRow>
    </Fragment>
  );
}

export default function CommonTable({ rows, headCells, isPagination, isTableFooter, statusLoad, updateTableName, footerList }) {
  //redux
  const dispatch = useDispatch();
  const handleChange = (event, index, columnId) => {
    const newRows = rows?.length > 0 && rows?.map((row, i) => (i === index ? { ...row, [columnId]: event.target.value } : row));
    if (updateTableName === "assessment") dispatch(updateAssessmentData(newRows));
  };

  const handleBlur = (event, index, columnId) => {
    if (updateTableName === "assessment") {
      const newRows =
        rows?.length > 0 &&
        rows?.map((row, i) => {
          if (i === index) {
            if (columnId === "assignedlevel" && event.target.value !== row?.currentlevel && !row?.levelchangereason) {
              row = { ...row, levelchangereason_Error: true };
            } else if (columnId === "levelchangereason" && row?.currentlevel !== row?.assignedlevel && !event.target.value) {
              row = { ...row, levelchangereason_Error: true };
            } else {
              row = { ...row, levelchangereason_Error: false };
            }
            return row;
          } else {
            return row;
          }
        });
      dispatch(updateAssessmentData(newRows));
    }
  };
  let rowsData = rows;
  const { currentData, currentPage, totalPages, setCurrentPage, setItemsPerPage } = usePagination(rows);
  if (isPagination) {
    rowsData = currentData;
  }

  const LoadWhile = () => {
    return [...Array(4)].map((item, itemIndex) => {
      return (
        <TableRow key={itemIndex}>
          <TableCell
            colSpan={headCells?.length + 1}
            sx={{ textAlign: "center" }}
            style={{
              borderBottom: "0px solid transparent",
              paddingBottom: 0.5,
              paddingRight: 2,
              paddingLeft: 0,
            }}
          >
            <Skeleton animation="wave" variant="rectangular" sx={{ height: 60, width: "100%", backgroundColor: "#F2F2F2" }} />
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Fragment>
      <Box>
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: {
              xl: 1000,
              lg: 380,
            },
          }}
          elevation={0}
        >
          <Table sx={{ minWidth: 750 }} aria-label="collapsible table" stickyHeader>
            <CustomTableHead rowCount={rows?.length} headCells={headCells} />
            <TableBody>
              {!statusLoad ? (
                rowsData?.length > 0 ? (
                  rowsData.map((row, rowIndex) => (
                    <Row
                      key={rowIndex}
                      row={row}
                      headCells={headCells}
                      statusLoad={statusLoad}
                      rowIndex={rowIndex}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={headCells?.length + 1} sx={{ textAlign: "center" }}>
                      No records found!
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <LoadWhile />
              )}
            </TableBody>
            {isTableFooter && (
              <TableFooter>
                {footerList?.length > 0 &&
                  footerList.map((fl, flIndex) => (
                    <StyledTableCell
                      align={"left"}
                      style={{
                        fontSize: 13,
                        fontWeight: 600,
                        color: "#262626",
                      }}
                      key={flIndex}
                    >
                      {fl?.value || fl?.label}
                    </StyledTableCell>
                  ))}
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Box>
      <Box>
        {isPagination && rows?.length > 0 && (
          <CusPagination
            count={totalPages}
            page={currentPage}
            handlePageChange={(e, page) => setCurrentPage(page)}
            handleRowsPerChange={setItemsPerPage}
            handleCurrentPage={() => setCurrentPage(1)}
          />
        )}
      </Box>
    </Fragment>
  );
}
